@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
footer{
  position: relative;
  background: #edeff5;
  width: 100%;
  margin-top: 50px;
}
.compsocLogo {
  width: 158.5px;
  height: auto;
  display: block;
  margin: auto;
}
.logo_name{
  font-weight: bold;
}
.imgfooter{
  width: 60px;
  height: 50px;
}
.imgfooterdsc{
  width: auto;
  height: 100px;
}
footer .content{
  max-width: 1250px;
  margin: auto;
  padding: 40px 25px 20px 20px;
}
footer .content .top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 50px; */
}
.content .top .logo-details{
  color: black;
  font-size: 30px;
}
.content .top .media-icons{
  display: flex;
}
.content .top .media-icons a{
  height: 40px;
  width: 40px;
  margin: 0 8px;
  margin-top: 5px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.iconlink{
  color: black;
}
footer .content .link-boxes{
  width: 90%;
  display: flex;
  justify-content: center;
}
footer .content .link-boxes .box1{
  width: calc(100% / 5 - 10px);
}
.content .link-boxes .box .link_name{
  color: black;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.link-boxes .box1 .link_name::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}
.content .link-boxes .box1 li{
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box1 li a{
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease
}
.content .link-boxes .box1 li a:hover{
  opacity: 1;
  text-decoration: underline;
}
.content .link-boxes .input-box1{
  margin-right: 55px;
}
/* .link-boxes .input-box1 input{
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #AFAFB6;
  background: #140B5C;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
  margin-top: 5px;
}
.link-boxes .input-box1 input::placeholder{
  color: #AFAFB6;
  font-size: 16px;
}
.link-boxes .input-box1 input[type="button"]{
  background: #fff;
  color: #140B5C;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box1 input[type="button"]:hover{
  opacity: 1;
} */
footer .bottom-details{
  width: 100%;
  background: #0F0844;
}
footer .bottom-details .bottom_text{
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a{
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover{
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a{
  margin-right: 10px;
}
@media (max-width: 900px) {
  footer .content .link-boxes{
    flex-wrap: wrap;
  }
}
@media (max-width: 700px){
  footer{
    position: relative;
  }
  .content .top .logo-details{
    font-size: 26px;
  }
  .content .top .media-icons a{
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .content .link-boxes .box1{
    width: calc(100% / 3 - 10px);
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 12px;
  }
}
@media (max-width: 520px){
  footer .content .top{
    flex-direction: column;
  }
  .content .top .media-icons{
    margin-top: 16px;
  }
  footer .content .link-boxes .box1{
    width: calc(100% / 2 - 10px);
  }
}


@media (max-width: 440px){
.imgfooterdsc{
  width: 110%;
  height: 100%;
}
}

@media (max-width: 635px){
.imgfooterdsc{
  width: auto;
  height: 50px;
}
}

@media (max-width: 416px){
.imgfooterdsc{
  width: auto;
  height: 40px;
}
footer .content .link-boxes{
  width: 50%;
}
}

@media (max-width: 348px){
footer .content .link-boxes{
  width: 25%;
}
}
.copyright_text{
  display:block;
}