.media {
  color: white;
  display: flex; /* Change to flexbox */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:10; 
  
}
.single {
  display: inline-block;
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;
  z-index:10; 
}
.single a {
  color: rgba(231, 228, 228);
  /* position: relative; */
  /* top: 0px; */
  transition: top 0.2s linear;
}
.single a:hover {
  color: white;
  transform: translateY(-5px);
}
@media (max-width: 570px) {
  .single {
    display: inline-block;
    margin-top: 50px;
    margin-left: 14.5px;
    margin-right: 14.5px;
  }
  .single img {
    width: 70%;
    margin-top: 120px;
    margin-left: 8px;
    margin-right: 8px;
  }
}
