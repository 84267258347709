.logoS{
  width: 400px;
  height: 400px;
  margin-top: 0;
  display: flex;
  align-items: center;
  margin-left: 20%;
  padding: 0;
  position: relative;
  right: 100px;

}

.darklogo{
  width: 35rem;

}
.Logo{
    /* width: 60%; */
    width: 35rem;
}

.logoS img{
margin: 0;
}
.sym{
  z-index: 4;
  width: 120px;
  right:17px;
  top: 35px;
  transition: 0.4s;
}

.logo1{
  z-index: 3;
  top: 0;
  transition: 0.4s;

}
.logo2{
z-index: 2;
top: 21px;
transition: 0.4s;


}
.logo3{
z-index: 1;
top: 41px;
transition: 0.4s;
}
.logoS:hover .logo3{
  top: 96px;
}
.logoS:hover .logo2{
  top: 51px;
}

.LogoSectionAbout {
  text-align: center;
}


/* General styles */
.track-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 40px; /* Center cards horizontally */
  margin-right: auto;
  width: calc(100% - 40px); /* Adjusted width for better centering and responsiveness */
}

.track-card {
  width: calc(100% - 40px); /* One card in one row for mobile screens */
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff; /* Background color */
  border-radius: 9px;
  box-shadow: 0px 4px 8px rgba(82, 4, 116, 0.5); /* Box shadow */
  transition: box-shadow 0.3s ease; /* Transition for hover effect */
}

@media (min-width: 768px) {
  .track-card {
    width: 900px; /* Two cards in one row with some gap for larger screens */
  }
}

@media (min-width: 992px) {
  .track-card {
    width: calc(30% - 20px); /* Three cards in one row with some gap for even larger screens */
    max-width: 300px; /* Set max-width for larger screens to control expansion */
  }
}

.track-card:hover {
  box-shadow: 0px 8px 16px rgba(120, 8, 161, 0.5); /* Increased box shadow on hover */
}

/* Typography */
.track-description h3 {
  font-weight: bold; /* Make the heading bold */
  font-size: 1.5rem; /* Adjusted font size for mobile */
  color: #5d0081; /* Title color */
  margin-bottom: 10px;
}

.track-description p {
  font-size: 1.8rem; /* Adjusted font size for mobile */
  color: #333333; /* Description color */
}


.LogoSectionAbout h1{
    font-weight: 700;
    text-align: left;
}


.Logo{

    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 2s infinite  alternate;
   }
   @-webkit-keyframes mover {
     0% { transform: translateY(0); }
     100% { transform: translateY(-10px); }
   }
   @keyframes mover {
     0% { transform: translateY(0); }
     100% { transform: translateY(-10px); }
   }

   @media  (max-width: 570px){
    .logoS{
      margin-top: 10rem;
      margin-bottom: -10rem;
    }
    .Logo{
     margin: 0 auto;
     width: 30rem;
    }
  }

  @media  (max-width: 570px){
    .logoS{
      margin-top: 10rem;
      margin-bottom: -10rem;
      /* background-color: black; */
      display: contents;
    }
    .Logo{
     margin: 0 auto;
     width: 30rem;
    }
  }

.imgHeight {
  height: 300px;
  border-radius: 30px;
}